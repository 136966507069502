import React from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

export default class Example extends React.Component {
  constructor(props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.state = {
      collapsed: true,
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  handleItemClick = () => {
    if (!this.state.collapsed) {
      this.setState({
        collapsed: true,
      })
    }
  }

  render() {
    return (
      <Navbar
        color="faded"
        dark
        expand="md"
        style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 5 }}
        className="p-0"
      >
        <NavbarBrand href="/" className="mr-auto">
          <img
            src="/images/logoHeader.png"
            alt="YouFirst Insurance California"
            width="200px"
            className="p-0"
          />
          <span className="ml-3">Lic#OK11696</span>
        </NavbarBrand>
        <NavbarToggler
          onClick={this.toggleNavbar}
          className="mr-2"
          aria-label="Menu Toggler"
        />
        <Collapse isOpen={!this.state.collapsed} navbar>
          <Nav navbar className="ml-auto">
            <NavItem
              className="pl-3 pb-2 pl-md-0"
              onClick={this.handleItemClick}
            >
              <NavLink className="text-white" href="#AboutUs">
                About Us
              </NavLink>
            </NavItem>
            <NavItem
              className="pl-3 pb-2 pl-md-0"
              onClick={this.handleItemClick}
            >
              <NavLink className="text-white" href="#OurServices">
                Services
              </NavLink>
            </NavItem>
            <NavItem
              className="pl-3 pb-2 pl-md-0"
              onClick={this.handleItemClick}
            >
              <NavLink className="text-white" href="#InsuranceProcess">
                Process
              </NavLink>
            </NavItem>
            <NavItem
              className="pl-3 pb-2 pl-md-0"
              onClick={this.handleItemClick}
            >
              <NavLink className="text-white" href="#WhyUs">
                Why Us
              </NavLink>
            </NavItem>
            <NavItem
              className="pl-3 pb-2 pl-md-0"
              onClick={this.handleItemClick}
            >
              <NavLink className="text-white" href="#ContactUs">
                Contact Us
              </NavLink>
            </NavItem>
            <NavItem
              className="pl-3 pb-2 pl-md-0"
              onClick={this.handleItemClick}
            >
              <NavLink className="text-primary" href="tel:+18777897411">
                <strong>+1 (877) 789-7411</strong>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}
